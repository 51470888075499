import { dependencies } from "./Dependencies";

const merchantId = dependencies.getFromLocalStorage("id");
const fullName = dependencies.getFromLocalStorage("MerchantName");
let firstName, lastName;
if (fullName) {
  [firstName, lastName] = fullName.split(" ");
}
const email = dependencies.getFromLocalStorage("Email");
const phoneNumber = dependencies.getFromLocalStorage("Phone");

export const addDjangoLangToCookies = lang => {
  if (window.location.hostname == "acceptstagingtest.paymobsolutions.com") {
    document.cookie = `django_language=${lang}; path=/; Domain=.paymobsolutions.com`;
  } else {
    document.cookie = `django_language=${lang}; path=/; Domain=.paymob.com`;
  }
};

let freshworksSourceScript, freshworksSettingsScript, freshchatScript;
export const initialFreshworks = () => {
  freshworksSourceScript = document.createElement("script");
  freshworksSourceScript.type = "text/javascript";
  freshworksSourceScript.src =
    "https://widget.freshworks.com/widgets/48000000323.js";
  freshworksSourceScript.async = true;
  freshworksSourceScript.defer = true;
  document.head.appendChild(freshworksSourceScript);

  freshworksSettingsScript = document.createElement("script");
  freshworksSettingsScript.textContent = `
      window.fwSettings = { 'widget_id': 48000000323 }; !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }()
  `;
  document.head.appendChild(freshworksSettingsScript);
};
export const intialFreshchat = () => {
  freshchatScript = document.createElement("script");

  const userInfo = {
    externalId: merchantId,
    firstName:firstName,
    lastName:lastName,
    email:email,
    phone:phoneNumber,
  }
   const config = {
    token: "4e93793d-b569-4de2-9d61-9ac6786df61a",
    host: "https://paymob.freshchat.com",
    tags: ["egypt_dashboard"],
    widgetUuid: "ec507eca-a794-4bad-96a6-1773aea44aad",
    ...userInfo
  };
 
  freshchatScript.textContent = `
  function initFreshChat() {
   window.fcWidget.init(${JSON.stringify(config)});
  }
    if (window?.fcWidget?.user) {
      window.fcWidget.user.setProperties(${JSON.stringify(userInfo)});  
    }
  function initialize(i, t) {
    var e; i.getElementById(t) ?
      initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0,
        e.src = "https://paymob.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
  }
  function initiateCall() { initialize(document, "Freshchat-js-sdk") }
  window.addEventListener ? window.addEventListener("load", initiateCall, !1) :
    window.attachEvent("load", initiateCall, !1);
  `;
  document.head.appendChild(freshchatScript);
};

export const cleanupFreshworks = () => {
  if (freshworksSourceScript) document.head.removeChild(freshworksSourceScript);
  if (freshworksSettingsScript)
    document.head.removeChild(freshworksSettingsScript);
};
export const cleanupFreshchat = () => {
  if (freshchatScript) document.head.removeChild(freshchatScript);
};